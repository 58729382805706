var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vue-admin-beautiful-wrapper",class:_vm.classObj},[('horizontal' === _vm.layout)?_c('div',{staticClass:"layout-container-horizontal",class:{
      fixed: _vm.header === 'fixed',
      'no-tabs-bar': _vm.tabsBar === 'false' || _vm.tabsBar === false,
    }},[_c('div',{class:_vm.header === 'fixed' ? 'fixed-header' : ''},[_c('vab-top-bar'),(_vm.tabsBar === 'true' || _vm.tabsBar === true)?_c('div',{class:{ 'tag-view-show': _vm.tabsBar }},[_c('div',{staticClass:"vab-main"},[_c('vab-tabs-bar')],1)]):_vm._e()],1),_c('div',{staticClass:"vab-main main-padding"},[_c('vab-app-main')],1)]):_c('div',{staticClass:"layout-container-vertical",class:{
      fixed: _vm.header === 'fixed',
      'no-tabs-bar': _vm.tabsBar === 'false' || _vm.tabsBar === false,
    }},[(_vm.device === 'mobile' && _vm.collapse === false)?_c('div',{staticClass:"mask",on:{"click":_vm.handleFoldSideBar}}):_vm._e(),_c('div',{attrs:{"id":"solid_left"}},[_c('sildBar')],1),_c('div',{staticClass:"vab-main",class:_vm.collapse ? 'is-collapse-main' : ''},[_c('div',{class:_vm.header === 'fixed' ? 'fixed-header' : ''},[_c('vab-nav-bar')],1),_c('vab-app-main')],1)]),_c('el-backtop')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }